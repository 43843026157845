<template>

  <div>
    <template>
      <div id="breadcrumb-alignment" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb
            :items="breadCrumb()"
          />
        </div>
      </div>
    </template>

    <!-- Table Container Card -->
    <b-card no-body>
    
        <b-card-body>
          <b-row>
         

            <b-col
              cols="12"
              md="4"
              class="mb-md-0 mb-2 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five">
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="areas"
                label="name"
                class="w-100"
                placeholder="Area"
                @input="filterTable"
                v-model="areaData"
                :clearable="false"
              >
              <template #selected-option="{ name }">
                  <span v-if="name && name.length < 30">{{ name }}</span>
                  <span v-if="name && name.length >= 30">{{ name.substring(0, 23) + '...' }}</span>
                </template>
              </v-select>
            </b-col>

          

            <b-col 
             cols="12"
             md="8"
             class="mb-md-0 mobile_tab_max_width_flex"
            >

              <div class="d-flex align-items-center justify-content-end mobile_tab_display_block">

                <b-button
                
                  variant="danger"
                  class="mobile-margin-bottom mr-1"
                  @click="resetFilter()"
                >
                  <span class="text-nowrap">Reset</span>
                </b-button>

                <b-button
                  :disabled="areaData._id == 'all-area'"
                  variant="warning"
                  class="mobile-margin-bottom mr-1"
                  :to="{ name: 'add-inventory-area-user', params: { area_id: areaData != null ? areaData._id : 'all-area' } }"
                >
                  <span class="text-nowrap">Add</span>
                </b-button>
              </div>
            </b-col>
          
          </b-row>
        </b-card-body>
      </b-card>

    
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">
        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0 mobile_tab_max_width_flex mobile_margin_bottom_1rem"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="filterTable"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
            class="mobile_tab_max_width_flex all_btn_tab"
          >
            <div class="d-flex align-items-center justify-content-end mobile-view mobile_tab_display_block">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1 mobile-margin-bottom mobile-margin-input mobile_margin_bottom_1rem "
                placeholder="Search..."
                @input="filterTable"
                autocomplete="off"
              />

              <!-- <b-button
                
                  variant="warning"
                  class="mobile-margin-bottom mr-1"
                  :to="{ name: 'add-inventory-area'}"
                >
                  <span class="text-nowrap">Add</span>
                </b-button> -->
              
              
            </div>
          </b-col>
        </b-row>

      </div>
      <b-table sticky-header
        ref="refUserListTable"
        class="position-relative"
        :items="dataList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :class="items.length <= 10 ? 'remove_overflow' : 'add_overflow'"
      >

        <!-- Column: Role -->
        <!-- <template #cell(budget)="items">
           
            <span @click="items.toggleDetails" class="wordBreak">${{ items.item.budget | currency}}</span>
         
        </template> -->
        <template #cell(site_manager)="items">
            <span @click="items.toggleDetails" v-b-tooltip.hover.v-primary.top :title="getFromAsgSite(items.item.sites)" v-if="items.item.sites.length > 0 && getFromAsgSite(items.item.sites).split(',').length > 3">
              {{ getFromAsgSite2(items.item.sites)[0] }}
              <b-badge v-if="getFromAsgSite2(items.item.sites)[1] != ''" pill variant="primary"> +{{ getFromAsgSite2(items.item.sites)[1] }} </b-badge>
            </span>
            <span @click="items.toggleDetails" v-if="items.item.sites.length > 0 && getFromAsgSite(items.item.sites).split(',').length <= 3">
              {{ getFromAsgSite2(items.item.sites)[0] }}
            </span>
            <span v-if="items.item.sites.length == 0">{{ 'N/A' }}</span>
         
        </template>

        <template #cell(name)="items">
           
            <span @click="items.toggleDetails" class="wordBreak">{{ items.item.inventoryArea.name | capitalize}}</span>
         
        </template>

        <template #cell(user)="items">
           
            <span @click="items.toggleDetails" class="wordBreak">{{ items.item.userData.full_name | capitalize }} <br> ( {{ items.item.userData.role | removeUnderCapitalize }} )</span>
        
        </template>
        
        <template #cell(site_in_charge)="items">

            <span @click="items.toggleDetails" class="wordBreak">{{ items.item.supervisorData[0].full_name | capitalize }} <br> ( {{ items.item.supervisorData[0].role | removeUnderCapitalize }} )</span>
        
        </template>

        <!-- <template #cell(phone_number)="items">
           
            <span class="wordBreak">{{ items.item.userData.phone_number }}</span>
      
        </template> -->

        <!-- <template #cell(sites)="items">
           
            <span v-b-tooltip.hover.v-primary.top :title="getFromAsgSite(items.item.sites)" v-if="items.item.sites.length > 0 && getFromAsgSite(items.item.sites).split(',').length > 3">
              {{ getFromAsgSite2(items.item.sites)[0] }}
              <b-badge v-if="getFromAsgSite2(items.item.sites)[1] != ''" pill variant="primary"> +{{ getFromAsgSite2(items.item.sites)[1] }} </b-badge>
            </span>
            <span v-if="items.item.sites.length > 0 && getFromAsgSite(items.item.sites).split(',').length <= 3">
              {{ getFromAsgSite2(items.item.sites)[0] }}
            </span>
            <span v-if="items.item.sites.length == 0">{{ 'N/A' }}</span>
    
        </template> -->

        
        <template #cell(status)="items">
           
            <b-badge @click="items.toggleDetails"
              pill
              :variant="variantColor(items.item.status)"
              class="text-capitalize"
              
            >
              {{ items.item.status}}
            </b-badge>
         
        </template>

        <!-- <template #cell(created_at)="items">
           
            <span>{{sitedateTime(items.item.created_at, 'DD MMM YYYY', 'HH:mm')}}</span>
         
        </template> -->

        <template #cell(info)="items">                
            <feather-icon :icon="items.detailsShowing ? 'EyeIcon' : 'EyeOffIcon' " @click="items.toggleDetails" />
        </template>

        <template #row-details="items">
          <div class="p-1 px-2 fbDetail mb-1">
              <b-row>
                <b-col cols="12" xl="12">
                    <table class="mt-2 mt-xl-0 w-100">
                        <tr class="mb-1">
                            <!-- <th class="pb-50" style="width: 16%">
                              <div class="d-flex align-items-center">
                                
                                <div class="ml-1">
                                  <h6 class="mb-0 mediumFonts">
                                    Site In-charge
                                  </h6>
                                </div>
                              </div>
                            </th>
                            <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                              <span @click="items.toggleDetails" class="wordBreak">{{ items.item.supervisorData.full_name | capitalize }} <br> ( {{ items.item.supervisorData.role | removeUnderCapitalize }} )</span>
                            </td> -->
                            
                            <th class="pb-50" style="width: 16%">
                              <div class="d-flex align-items-center">
                                
                                <div class="ml-1">
                                  <h6 class="mb-0 mediumFonts">
                                    Budget
                                  </h6>
                                </div>
                              </div>
                            </th>
                            <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                              <span class="wordBreak">${{ items.item.budget | currency}}</span>
                            </td>
                            <!-- <th class="pb-50" style="width: 16%">
                              <div class="d-flex align-items-center">
                                
                                <div class="ml-1">
                                  <h6 class="mb-0 mediumFonts">
                                    Site Manager
                                  </h6>
                                </div>
                              </div>
                            </th>
                            <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                              <span v-b-tooltip.hover.v-primary.top :title="getFromAsgSite(items.item.sites)" v-if="items.item.sites.length > 0 && getFromAsgSite(items.item.sites).split(',').length > 3">
                                {{ getFromAsgSite2(items.item.sites)[0] }}
                                <b-badge v-if="getFromAsgSite2(items.item.sites)[1] != ''" pill variant="primary"> +{{ getFromAsgSite2(items.item.sites)[1] }} </b-badge>
                              </span>
                              <span v-if="items.item.sites.length > 0 && getFromAsgSite(items.item.sites).split(',').length <= 3">
                                {{ getFromAsgSite2(items.item.sites)[0] }}
                              </span>
                              <span v-if="items.item.sites.length == 0">{{ 'N/A' }}</span>
                            </td> -->

                            <th class="pb-50" style="width: 16%">
                              <div class="d-flex align-items-center">
                                
                                <div class="ml-1">
                                  <h6 class="mb-0 mediumFonts">
                                    Contact Number 
                                  </h6>
                                </div>
                              </div>
                            </th>
                            <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                              <span class="wordBreak">{{ items.item.userData.phone_number }}</span>
                            </td>
                            <th class="pb-50" style="width: 16%">
                                  <div class="d-flex align-items-center">
                                    
                                    <div class="ml-1">
                                      <h6 class="mb-0 mediumFonts">
                                        Added On 
                                      </h6>
                                    </div>
                                  </div>
                                </th>
                                <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                  <span>{{sitedateTime(items.item.created_at, 'DD MMM YYYY', 'HH:mm')}}</span>
                                </td>

                        </tr>
                          <!-- <tr class="mb-1">
                                <th class="pb-50" style="width: 16%">
                                  <div class="d-flex align-items-center">
                                    
                                    <div class="ml-1">
                                      <h6 class="mb-0 mediumFonts">
                                        Added On 
                                      </h6>
                                    </div>
                                  </div>
                                </th>
                                <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                  <span>{{sitedateTime(items.item.created_at, 'DD MMM YYYY', 'HH:mm')}}</span>
                                </td>
                                <th class="pb-50" style="width: 16%">
                                </th>
                                <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                    
                                </td>
                                <th class="pb-50" style="width: 16%">
                                </th>
                                <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                    
                                </td>
                          </tr> -->
                          <tr class="mb-1">
                                <th class="pb-50" style="width: 16%">
                                  <b-button
                                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                      variant="outline-secondary"
                                      @click="items.toggleDetails"
                                      size="sm"
                                      class="ml-1"
                                    >
                                      Hide Detail
                                    </b-button>
                                </th>
                                <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                    
                                </td>
                                <th class="pb-50" style="width: 16%">
                                </th>
                                <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                    
                                </td>
                                <th class="pb-50" style="width: 16%">
                                </th>
                                <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                    
                                </td>
                          </tr>
                    </table>
                </b-col>
              </b-row>

            </div>
        </template>

        <template #cell(actions)="items">
          <b-link v-b-tooltip.hover.v-warning title="Edit" variant="outline-warning" :to="{ name: 'edit-inventory-area-user', params: { id: items.item._id } }">
                <feather-icon icon="EditIcon" class="mediumSize mr-1" />	
            </b-link>

            <b-link v-b-tooltip.hover.v-warning :title= "items.item.status == 'active' ? 'Mark Inactive' : 'Mark Active'" variant="outline-warning" @click="statusChange(items.item)">
                  <feather-icon :icon="items.item.status == 'active' ? 'CheckCircleIcon' : 'CircleIcon'" class="mediumSize mr-1" />	
              </b-link>
              

            <b-link v-b-tooltip.hover.v-warning title="Delete" variant="outline-warning" @click="deleteItem(items.item)">
                <feather-icon icon="Trash2Icon" class="mediumSize mr-1" />	
            </b-link>
          <!-- <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            

            <b-dropdown-item :to="{ name: 'edit-inventory-area-user', params: { id: items.item._id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item @click="statusChange(items.item)">
              <feather-icon :icon="items.item.status == 'active' ? 'UserMinusIcon' : 'UserCheckIcon'" />
              <span class="align-middle ml-50" v-if="items.item.status == 'active'">Inactive</span>
              <span class="align-middle ml-50" v-if="items.item.status == 'inactive'">Active</span>
            </b-dropdown-item>

            <b-dropdown-item @click="deleteItem(items.item)">
              <feather-icon icon="Trash2Icon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>

            

            
          </b-dropdown>  -->
        </template>

        

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRecords"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @input="filterTable"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BBreadcrumb,BCardBody, VBTooltip
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { POST_API } from "../../../store/actions.type"
import Ripple from 'vue-ripple-directive'

export default {
  components: {

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardBody,
    vSelect,
    BBreadcrumb
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip
  },
  data() {
    return {

        tableColumns: [
          { key: 'info', label:'INFO',sortable: false , thStyle:  {width: '1%'}},
          
          { key: 'name', label: 'Area Name', sortable: true , thStyle:  {width: '18%'}},
          // { key: 'sites', label: 'Project Sites', sortable: false, thStyle: { width: '15%' } },
          { key: 'site_manager', label: 'Project Site', sortable: false, thStyle: { width: '20%' } },
          // { key: 'budget', label: 'Budget', sortable: true, thStyle: { width: '20%' } },
          { key: 'user', label: 'Area In-Charge', sortable: true, thStyle: { width: '20%' } },
          { key: 'site_in_charge', label: 'Site In-Charge', sortable: false, thStyle: { width: '17%' } },
          // { key: 'phone_number', label: 'Contact Number', sortable: false, thStyle: { width: '12%' } },

          { key: 'status', label: 'Status', sortable: false , thStyle:  {width: '7%'}},
          // { key: 'created_at', label: 'Added On', sortable: true , thStyle:  {width: '15%'}},
          { key: 'actions', label: 'Action', sortable: false , thStyle:  {width: '17%'}},
        ],
        areaData:null,
        areas:[],
        items:[],
        isSortDirDesc: false,
        perPageOptions: [10, 20, 50, 100],
        perPage : 10,
        sortBy : 'id',
       
        totalRecords : 0,
        currentPage : 1,
        searchQuery:'',
        from:null,
        to:null,
    }
  },
  methods : {
    
    breadCrumb(){
      var item = [{
        to:{name:'client-dashboard'},
        text: 'Dashboard',
      },{
        to:null,
        text: 'Inventory'
      },{
        to:null,
        text: 'Settings'
      },{
        to:{name:'inventory-area'},
        text: 'Area Management',
      },
      {
        to:{name:'inventory-area'},
        text: 'View Area Users',
      }, {
        to: null,
        text: 'Inventory Site Area User',
        active: true
      }];
      return item;
    },
    
    dataList(){

      return this.$store.dispatch(POST_API, {
          data:{
            //area:this.$route.params.area_id,
            area: this.areaData ? this.areaData._id : null,
            page: this.currentPage,
            keyword: this.searchQuery,
            rowsPerPage:this.perPage,
            sortBy:this.sortBy,
            sortDirection:this.isSortDirDesc,
            role:this.$store.getters.currentUser.role,
          },
          api: '/api/inventory-area-user-list'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
          } else {
              this.items = this.$store.getters.getResults.data.docs;
              this.totalRecords = this.$store.getters.getResults.data.total;
              this.perPage = this.$store.getters.getResults.data.limit;
              this.from = this.$store.getters.getResults.data.from
              this.to = this.$store.getters.getResults.data.to
              return this.items;
          }
      });
    },
    filterTable(){
      this.$refs.refUserListTable.refresh();
    },
    variantColor(status){
      if (status == 'active') {
        return 'success';
      }else{
        return 'primary';
      }
    },
    
    statusChange(item){
      if (item.status == 'active') {
          var msg = 'Are you sure want to inactivate this record?';
          var message='Area User Inactivated Successfully.';
      } 
      else{
          var msg = 'Are you sure want to activate this record?';
          var message='Area User Activated Successfully.';
      }

      Swal.fire({
                title: 'Please Confirm',
                text: msg,
                icon: 'warning',
                position: 'top-center',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
              })
            .then(result => {
              if (result.value) {
                  return this.$store.dispatch(POST_API, {
                    data:{
                          id: item._id,
                          status:item.status
                        },
                    api:"/api/change-inventory-area-user-status",
                      })
                      .then(() => {
                          if (this.$store.getters.containsErrors) {
                              this.error_message = this.$store.getters.getErrors;
                              //this.showAlert();
                              this.errorAlert();
                          } else {
                            
                            Swal.fire({
                              position: 'center',
                              icon: 'success',
                              title: message,
                              showConfirmButton: false,
                              timer: 1500
                            })
                            
                            this.$refs.refUserListTable.refresh();
                          }
                      });
              }
          })
          .catch(err => {
             
          })
    },
    deleteItem(item){
      var msg = 'Are you sure want to delete this record?';
      var message = 'Area User Deleted Successfully.';


      Swal.fire({
        title: 'Please Confirm',
        text: msg,
        icon: 'warning',
        position: 'top-center',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })
        .then(result => {
          if (result.value) {
            return this.$store.dispatch(POST_API, {
              data: {
                id: item._id,
                status: 'deleted'
              },
              api: "/api/change-inventory-area-user-status",
            })
              .then(() => {
                if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
                } else {

                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: message,
                    showConfirmButton: false,
                    timer: 1500
                  })

                  this.$refs.refUserListTable.refresh();
                }
              });
          }
        })
        .catch(err => {

        })
      
    },
    getFromAsgSite(site) {
      if (site.length == 0) {
        return 'N/A';
      } else {
        var arr = [];
        for (var i = 0; i < site.length; i++) {
          if (this.$store.getters.currentUser.role == 'admin') {
            arr.push(site[i].site_name);
          } else if (this.$store.getters.currentUser.om_sites.indexOf(site[i]._id) >= 0) {
            arr.push(site[i].site_name);
          }
        }

        return arr.join(', ');

      }
    },
    getFromAsgSite2(site) {
      if (site.length == 0) {
        return 'N/A';
      } else {
        var arr = [];
        for (var i = 0; i < site.length; i++) {
          // if (this.$store.getters.currentUser.role == 'admin') {
            arr.push(site[i].site_name);
          // } else if (this.$store.getters.currentUser.om_sites.indexOf(site[i]._id) >= 0) {
          //   arr.push(site[i].site_name);
          // }
        }

        if (arr.length > 3) {
          return [arr[0] + ', ' + arr[1] + ', ' + arr[2], (arr.length - 3)]
        } else {
          return [arr.join(', '), ''];
        }

      }
    },
    getAreas(){
      return this.$store.dispatch(POST_API, {
        data: {
          
        },
        api: '/api/all-inventory-areas'
      })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
          } else {
            this.areas = this.$store.getters.getResults.data;
            var obj = {
              _id: 'all-area',
              name: 'All Area'
            }
            this.areas.unshift(obj);
            return this.areas;
          }
        });
    },
    updateSite() {
      this.getAreas();

      this.areaData = {
        _id: 'all-area',
        name: 'All Area'
      };

      this.filterTable();

    },
    resetFilter() {
      //this.updateSite();
      this.setParamsValue();
    },
    async setParamsValue() {
      await this.getAreas();

      if (this.$route.params.area_id != '') {
        var areaData = this.areas.filter(item => { return item._id == this.$route.params.area_id });
        this.areaData = areaData.length > 0 ? areaData[0] : { _id: this.$route.params.area_id, name: 'Area' };
      }

      this.filterTable();

    },
  },
  mounted(){
    this.setParamsValue();
    
  }
  
}
</script>


<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
